import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import config from '../../config'

const API_URL = `${config.baseUrl}`;

// Async thunk for getting article content
export const getArticleContent = createAsyncThunk(
  "article/content",
  async () => {
    try {
      const ACCESS_TOKEN = localStorage.getItem("token");

      const response = await axios.get(`${API_URL}/list-article`, {
        headers: {
          Authorization: `Bearer ${ACCESS_TOKEN}`,
        },
      });
      return response.data.data;
    } catch (error) {
      throw error;
    }
  }
);

// Async thunk for deleting a article
export const deleteOurArticle = createAsyncThunk(
  "article/delete",
  async (id = 1) => {
    try {
      await axios.delete(`${API_URL}/delete-article/${id}`);

      return id;
    } catch (error) {
      throw error;
    }
  }
);

// Async thunk for updating a article = createAsyncThunk(
export const updateArticleName = createAsyncThunk(
  "article/update",
  async ({ id,heading,paragraph,image}) => {
    try {
      const ACCESS_TOKEN = localStorage.getItem("token");
      const response = await axios.put(
        `${API_URL}/update-article/${id}`, 
        { heading:heading,paragraph:paragraph,image:image},
        {
          headers: {
            Authorization: `Bearer ${ACCESS_TOKEN}`,
          },
        }
      );

      return response.data.data;
    } catch (error) {
      throw error;
    }
  }
);

// Async thunk for adding a new article
export const addNewArticle = createAsyncThunk(
  "article/addNew",
  async (newArticleData) => {
    try {
      const ACCESS_TOKEN = localStorage.getItem("token");

      const response = await axios.post(
        `${API_URL}/add-article`,
        newArticleData,
        {
          headers: {
            Authorization: `Bearer ${ACCESS_TOKEN}`,
          },
        }
      );

      return response.data.data; // Return the newly added article data
    } catch (error) {
      throw error;
    }
  }
);

// Define the article slice
export const articleSlice = createSlice({
  name: "article",
  initialState: {
    isLoading: false,
    leads: [],
    articleId: null, 
    articleHeading: "",
    articleImage:"",
    articleParagraph:"",
    articleStatus:"",
    error: null,
  },
  reducers: {
    // Define your reducers here if needed
    setArticleData: (state, action) => {
      state.articleId = action.payload.id;
      state.articleHeading = action.payload.heading;
      state.articleParagraph = action.payload.paragraph;
      state.articleImage = action.payload.image;
      state.articleStatus = action.payload.status;
      state.articleMetaDescription = action.payload.meta_description;
      state.articleMetaKeyword = action.payload.meta_keyword;
      state.articleMetaTitle = action.payload.meta_title;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getArticleContent.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getArticleContent.fulfilled, (state, action) => {
        state.leads = action.payload;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(getArticleContent.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(deleteOurArticle.fulfilled, (state, action) => {
        const deletedArticleId = action.payload;
        state.leads = state.leads.filter(
          (article) => article.id !== deletedArticleId
        );
        state.isLoading = false;
        state.error = null;
      })
      .addCase(deleteOurArticle.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(updateArticleName.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateArticleName.fulfilled, (state) => {
        state.isLoading = false;
        state.error = null;
      })
      .addCase(updateArticleName.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
        console.error("Error updating article name:", action.error);
      })
      .addCase(addNewArticle.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(addNewArticle.fulfilled, (state, action) => {
        state.leads.push(action.payload);
        state.isLoading = false;
        state.error = null;
      })
      .addCase(addNewArticle.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message
          ? action.error.message
          : "Failed to add a new Article";
      });
  },
});

export const { deleteLead, setArticleData } = articleSlice.actions;

export default articleSlice.reducer;