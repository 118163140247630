import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import config from '../../config'

const API_URL = `${config.baseUrl}`;

// Async thunk for getting top content
export const getTopContent = createAsyncThunk(
  "top/content",
  async () => {
    try {
      const ACCESS_TOKEN = localStorage.getItem("token");

      const response = await axios.get(`${API_URL}/list-top`, {
        headers: {
          Authorization: `Bearer ${ACCESS_TOKEN}`,
        },
      });
      return response.data.data;
    } catch (error) {
      throw error;
    }
  }
);

// Async thunk for deleting a top
export const deleteOurTop = createAsyncThunk(
  "top/delete",
  async (id = 1) => {
    try {
      await axios.delete(`${API_URL}/delete-top/${id}`);

      return id;
    } catch (error) {
      throw error;
    }
  }
);

// Async thunk for updating a top = createAsyncThunk(
export const updateTopName = createAsyncThunk(
  "top/update",
  async ({ id,week_id, home_team,away_team,home_image,away_image,winning_team,winning_value}) => {
    try {
      const ACCESS_TOKEN = localStorage.getItem("token");
      const response = await axios.put(
        `${API_URL}/update-top/${id}`, 
        { week_id:week_id,home_team: home_team,away_team:away_team,home_image:home_image,away_image:away_image,winning_team:winning_team,winning_value:winning_value},
        {
          headers: {
            Authorization: `Bearer ${ACCESS_TOKEN}`,
          },
        }
      );

      return response.data.data;
    } catch (error) {
      throw error;
    }
  }
);

// Async thunk for adding a new top
export const addNewTop = createAsyncThunk(
  "top/addNew",
  async (newTopData) => {
    try {
      const ACCESS_TOKEN = localStorage.getItem("token");

      const response = await axios.post(
        `${API_URL}/add-top`,
        newTopData,
        {
          headers: {
            Authorization: `Bearer ${ACCESS_TOKEN}`,
          },
        }
      );

      return response.data.data; // Return the newly added top data
    } catch (error) {
      throw error;
    }
  }
);

// Define the top slice
export const topSlice = createSlice({
  name: "top",
  initialState: {
    isLoading: false,
    leads: [],
    topId: null, 
    topWeekId: "",
    topHomeTeam: "",
    topAwayTeam:"",
    topHomeImage:"",
    topAwayImage:"",
    topWinningTeam:"",
    topWinningValue:"",
    topStatus:"",
    error: null,
  },
  reducers: {
    // Define your reducers here if needed
    setTopData: (state, action) => {
      state.topId = action.payload.id;
      state.topWeekId = action.payload.week_id;
      state.topHomeTeam = action.payload.home_team;
      state.topAwayTeam = action.payload.away_team;
      state.topHomeImage = action.payload.home_image;
      state.topAwayImage = action.payload.away_image;
      state.topWinningTeam = action.payload.winning_team;
      state.topWinningValue = action.payload.winning_value;
      state.topStatus = action.payload.status;
      state.topMetaDescription = action.payload.meta_description;
      state.topMetaKeyword = action.payload.meta_keyword;
      state.topMetaTitle = action.payload.meta_title;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTopContent.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getTopContent.fulfilled, (state, action) => {
        state.leads = action.payload;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(getTopContent.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(deleteOurTop.fulfilled, (state, action) => {
        const deletedTopId = action.payload;
        state.leads = state.leads.filter(
          (top) => top.id !== deletedTopId
        );
        state.isLoading = false;
        state.error = null;
      })
      .addCase(deleteOurTop.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(updateTopName.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateTopName.fulfilled, (state) => {
        state.isLoading = false;
        state.error = null;
      })
      .addCase(updateTopName.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
        console.error("Error updating top name:", action.error);
      })
      .addCase(addNewTop.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(addNewTop.fulfilled, (state, action) => {
        state.leads.push(action.payload);
        state.isLoading = false;
        state.error = null;
      })
      .addCase(addNewTop.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message
          ? action.error.message
          : "Failed to add a new Top";
      });
  },
});

export const { deleteLead, setTopData } = topSlice.actions;

export default topSlice.reducer;