import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import config from '../../config'

const API_URL = `${config.baseUrl}`;

// Async thunk for getting week content
export const getWeekContent = createAsyncThunk(
  "week/content",
  async () => {
    try {
      const ACCESS_TOKEN = localStorage.getItem("token");

      const response = await axios.get(`${API_URL}/list-week`, {
        headers: {
          Authorization: `Bearer ${ACCESS_TOKEN}`,
        },
      });
      return response.data.data;
    } catch (error) {
      throw error;
    }
  }
);

// Async thunk for deleting a week
export const deleteOurWeek = createAsyncThunk(
  "week/delete",
  async (id = 1) => {
    try {
      await axios.delete(`${API_URL}/delete-week/${id}`);

      return id;
    } catch (error) {
      throw error;
    }
  }
);

// Async thunk for updating a week name
export const updateWeekName = createAsyncThunk(
  "week/update",
  async ({ id, newName,newShortDescription,newFee,newDescription ,newMetaTitle,newMetaDescription }) => {
    try {
      const ACCESS_TOKEN = localStorage.getItem("token");
      const response = await axios.put(
        `${API_URL}/update-week/${id}`, 
        { week_name: newName,week_fee:newFee,short_description:newShortDescription,description:newDescription,meta_title:newMetaTitle,meta_description:newMetaDescription },
        {
          headers: {
            Authorization: `Bearer ${ACCESS_TOKEN}`,
          },
        }
      );

      return response.data.data;
    } catch (error) {
      throw error;
    }
  }
);

// Async thunk for adding a new week
export const addNewWeek = createAsyncThunk(
  "week/addNew",
  async (newWeekData) => {
    try {
      const ACCESS_TOKEN = localStorage.getItem("token");

      const response = await axios.post(
        `${API_URL}/add-week`,
        newWeekData,
        {
          headers: {
            Authorization: `Bearer ${ACCESS_TOKEN}`,
          },
        }
      );

      return response.data.data; // Return the newly added week data
    } catch (error) {
      throw error;
    }
  }
);

// Define the week slice
export const weekSlice = createSlice({
  name: "week",
  initialState: {
    isLoading: false,
    weeks: [],
    weekId: null, 
    weekName: "",
    weekFee:"",
    weekStatus:"",
    error: null,
  },
  reducers: {
    // Define your reducers here if needed
    setWeekData: (state, action) => {
      state.weekId = action.payload.id;
      state.weekStatus = action.payload.status;
      state.weekName = action.payload.week_name;
      state.weekFee = action.payload.week_fee;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getWeekContent.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getWeekContent.fulfilled, (state, action) => {
        state.weeks = action.payload;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(getWeekContent.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(deleteOurWeek.fulfilled, (state, action) => {
        const deletedWeekId = action.payload;
        state.weeks = state.weeks.filter(
          (week) => week.id !== deletedWeekId
        );
        state.isLoading = false;
        state.error = null;
      })
      .addCase(deleteOurWeek.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(updateWeekName.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateWeekName.fulfilled, (state) => {
        state.isLoading = false;
        state.error = null;
      })
      .addCase(updateWeekName.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
        console.error("Error updating Week name:", action.error);
      })
      .addCase(addNewWeek.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(addNewWeek.fulfilled, (state, action) => {
        state.weeks.push(action.payload); // Add the new data to the end of the array
        state.weeks.reverse(); // Reverse the order of the array
        state.isLoading = false;
        state.error = null;
      })
      
      .addCase(addNewWeek.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message
          ? action.error.message
          : "Failed to add a new Week";
      });
  },
});

export const { deleteLead, setWeekData } = weekSlice.actions;

export default weekSlice.reducer;