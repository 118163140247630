import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import config from '../../config'

const API_URL = `${config.baseUrl}`;

// Async thunk for getting blog content
export const getBlogContent = createAsyncThunk(
  "blog/content",
  async () => {
    try {
      const ACCESS_TOKEN = localStorage.getItem("token");

      const response = await axios.get(`${API_URL}/list-blog`, {
        headers: {
          Authorization: `Bearer ${ACCESS_TOKEN}`,
        },
      });
      return response.data.data;
    } catch (error) {
      throw error;
    }
  }
);

// Async thunk for deleting a blog
export const deleteOurBlog = createAsyncThunk(
  "blog/delete",
  async (id = 1) => {
    try {
      await axios.delete(`${API_URL}/delete-blog/${id}`);

      return id;
    } catch (error) {
      throw error;
    }
  }
);

// Async thunk for updating a blog name
export const updateBlogName = createAsyncThunk(
  "blog/update",
  async ({ id, newName, newImage,newSlug,parent_id,newShortDescription,newDescription ,newMetaTitle,newMetaDescription,newMetaKeyword }) => {
    try {
      const ACCESS_TOKEN = localStorage.getItem("token");
      const response = await axios.put(
        `${API_URL}/update-blog/${id}`, 
        { name: newName,slug:newSlug,image:newImage,parent_id:parent_id,short_description:newShortDescription,description:newDescription,meta_title:newMetaTitle,meta_description:newMetaDescription,meta_keywords:newMetaKeyword },
        {
          headers: {
            Authorization: `Bearer ${ACCESS_TOKEN}`,
          },
        }
      );

      return response.data.data;
    } catch (error) {
      throw error;
    }
  }
);

// Async thunk for adding a new blog
export const addNewBlog = createAsyncThunk(
  "blog/addNew",
  async (newBlogData) => {
    try {
      const ACCESS_TOKEN = localStorage.getItem("token");

      const response = await axios.post(
        `${API_URL}/add-blog`,
        newBlogData,
        {
          headers: {
            Authorization: `Bearer ${ACCESS_TOKEN}`,
          },
        }
      );

      return response.data.data; // Return the newly added blog data
    } catch (error) {
      throw error;
    }
  }
);

// Define the blog slice
export const blogSlice = createSlice({
  name: "blog",
  initialState: {
    isLoading: false,
    leads: [],
    blogId: null, 
    blogName: "",
    blogStatus:"",
    blogSlug:"",
    blogImage:"",
    blogParentId:"", 
    blogMetaTitle:"",
    blogShortDescription:"",
    blogDescription:"",
    blogMetaDescription:"",
    blogMetaKeyword:"",
    error: null,
  },
  reducers: {
    // Define your reducers here if needed
    setBlogData: (state, action) => {
      state.blogId = action.payload.id;
      state.blogName = action.payload.name;
      state.blogImage = action.payload.image;
      state.blogSlug = action.payload.slug;
      state.blogStatus = action.payload.status;
      state.blogParentId = action.payload.parent_id;
      state.blogMetaTitle = action.payload.meta_title;
      state.blogShortDescription = action.payload.short_description;
      state.blogDescription = action.payload.description;
      state.blogMetaDescription = action.payload.meta_description;
      state.blogMetaKeyword = action.payload.meta_keywords;
      

    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBlogContent.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getBlogContent.fulfilled, (state, action) => {
        state.leads = action.payload;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(getBlogContent.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(deleteOurBlog.fulfilled, (state, action) => {
        const deletedBlogId = action.payload;
        state.leads = state.leads.filter(
          (blog) => blog.id !== deletedBlogId
        );
        state.isLoading = false;
        state.error = null;
      })
      .addCase(deleteOurBlog.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(updateBlogName.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateBlogName.fulfilled, (state) => {
        state.isLoading = false;
        state.error = null;
      })
      .addCase(updateBlogName.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
        console.error("Error updating blog name:", action.error);
      })
      .addCase(addNewBlog.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(addNewBlog.fulfilled, (state, action) => {
        state.leads.push(action.payload); // Add the new data to the end of the array
        state.leads.reverse(); // Reverse the order of the array
        state.isLoading = false;
        state.error = null;
      })
      
      .addCase(addNewBlog.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message
          ? action.error.message
          : "Failed to add a new blog";
      });
  },
});

export const { deleteLead, setBlogData } = blogSlice.actions;

export default blogSlice.reducer;