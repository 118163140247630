import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import config from '../../config';

const API_URL = `${config.baseUrl}`;

// Async thunk for getting win content
export const getWinContent = createAsyncThunk(
  "win/content",
  async () => {
    try {
      const ACCESS_TOKEN = localStorage.getItem("token");

      const response = await axios.get(`${API_URL}/list-win`, {
        headers: {
          Authorization: `Bearer ${ACCESS_TOKEN}`,
        },
      });

      // Ensure response has the expected format
      if (response.data && response.data.data) {
        return response.data.data;
      } else {
        throw new Error("Unexpected response format");
      }
    } catch (error) {
      throw error;
    }
  }
);

// Async thunk for deleting a win
export const deleteOurWin = createAsyncThunk(
  "win/delete",
  async (id = 1) => {
    try {
      await axios.delete(`${API_URL}/delete-win/${id}`);
      return id;
    } catch (error) {
      throw error;
    }
  }
);

// Async thunk for updating a win
export const updateWinName = createAsyncThunk(
  "win/update",
  async ({ id, total_win,total_loss }) => {
    try {
      const ACCESS_TOKEN = localStorage.getItem("token");
      const response = await axios.put(
        `${API_URL}/update-win/${id}`, 
        { total_win,total_loss },
        {
          headers: {
            Authorization: `Bearer ${ACCESS_TOKEN}`,
          },
        }
      );

      // Ensure response has the expected format
      if (response.data && response.data.data) {
        return response.data.data;
      } else {
        throw new Error("Unexpected response format");
      }
    } catch (error) {
      throw error;
    }
  }
);

// Async thunk for adding a new win
export const addNewWin = createAsyncThunk(
  "win/addNew",
  async (newWinData) => {
    try {
      const ACCESS_TOKEN = localStorage.getItem("token");

      const response = await axios.post(
        `${API_URL}/add-win`,
        newWinData,
        {
          headers: {
            Authorization: `Bearer ${ACCESS_TOKEN}`,
          },
        }
      );

      // Ensure response has the expected format
      if (response.data && response.data.data) {
        return response.data.data;
      } else {
        throw new Error("Unexpected response format");
      }
    } catch (error) {
      throw error;
    }
  }
);

// Define the win slice
export const winSlice = createSlice({
  name: "win",
  initialState: {
    isLoading: false,
    leads: [],
    winId: null,
    winTotalWin: "",
    winTotalLoss:"",
    winStatus: "",
    error: null,
  },
  reducers: {
    // Define your reducers here if needed
    setWinData: (state, action) => {
      const { id, total_win,total_loss } = action.payload || {};
      state.winId = id || null;
      state.winTotalWin = total_win || "";
      state.winTotalLoss = total_loss || "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getWinContent.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getWinContent.fulfilled, (state, action) => {
        state.leads = Array.isArray(action.payload) ? action.payload : [];
        state.isLoading = false;
        state.error = null;
      })
      .addCase(getWinContent.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(deleteOurWin.fulfilled, (state, action) => {
        const deletedWinId = action.payload;
        state.leads = state.leads.filter((win) => win.id !== deletedWinId);
        state.isLoading = false;
        state.error = null;
      })
      .addCase(deleteOurWin.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(updateWinName.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateWinName.fulfilled, (state, action) => {
        // Ensure action.payload is valid and contains expected data
        const updatedWin = action.payload || {};
        const index = state.leads.findIndex((win) => win.id === updatedWin.id);
        if (index !== -1) {
          state.leads[index] = updatedWin;
        }
        state.isLoading = false;
        state.error = null;
      })
      .addCase(updateWinName.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
        console.error("Error updating win:", action.error);
      })
      .addCase(addNewWin.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(addNewWin.fulfilled, (state, action) => {
        state.leads.push(action.payload || {});
        state.isLoading = false;
        state.error = null;
      })
      .addCase(addNewWin.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || "Failed to add a new Win";
      });
  },
});

export const { setWinData } = winSlice.actions;

export default winSlice.reducer;
