import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import config from '../../config';

const API_URL = `${config.baseUrl}`;

// Async thunk for getting pick content
export const getPickContent = createAsyncThunk(
  "pick/content",
  async () => {
    try {
      const ACCESS_TOKEN = localStorage.getItem("token");

      const response = await axios.get(`${API_URL}/list-pick`, {
        headers: {
          Authorization: `Bearer ${ACCESS_TOKEN}`,
        },
      });

      // Ensure the response contains the expected data structure
      if (response.data && response.data.data) {
        return response.data.data;
      } else {
        throw new Error("Unexpected response format");
      }
    } catch (error) {
      throw error;
    }
  }
);

// Async thunk for deleting a pick
export const deleteOurPick = createAsyncThunk(
  "pick/delete",
  async (id = 1) => {
    try {
      await axios.delete(`${API_URL}/delete-pick/${id}`);
      return id;
    } catch (error) {
      throw error;
    }
  }
);

// Async thunk for updating a pick
export const updatePickName = createAsyncThunk(
  "pick/update",
  async ({ id, week_id, home_team, away_team, home_image, away_image, winning_team, winning_value }) => {
    try {
      const ACCESS_TOKEN = localStorage.getItem("token");
      const response = await axios.put(
        `${API_URL}/update-pick/${id}`,
        { week_id, home_team, away_team, home_image, away_image, winning_team, winning_value },
        {
          headers: {
            Authorization: `Bearer ${ACCESS_TOKEN}`,
          },
        }
      );

      // Ensure the response contains the expected data structure
      if (response.data && response.data.data) {
        return response.data.data;
      } else {
        throw new Error("Unexpected response format");
      }
    } catch (error) {
      throw error;
    }
  }
);

// Async thunk for adding a new pick
export const addNewPick = createAsyncThunk(
  "pick/addNew",
  async (newPickData) => {
    try {
      const ACCESS_TOKEN = localStorage.getItem("token");

      const response = await axios.post(
        `${API_URL}/add-pick`,
        newPickData,
        {
          headers: {
            Authorization: `Bearer ${ACCESS_TOKEN}`,
          },
        }
      );

      // Ensure the response contains the expected data structure
      if (response.data && response.data.data) {
        return response.data.data;
      } else {
        throw new Error("Unexpected response format");
      }
    } catch (error) {
      throw error;
    }
  }
);

// Define the pick slice
export const pickSlice = createSlice({
  name: "pick",
  initialState: {
    isLoading: false,
    leads: [],
    pickId: null,
    pickWeekId: "",
    pickHomeTeam: "",
    pickAwayTeam: "",
    pickHomeImage: "",
    pickAwayImage: "",
    pickWinningTeam: "",
    pickWinningValue: "",
    pickStatus: "",
    error: null,
  },
  reducers: {
    // Define your reducers here if needed
    setPickData: (state, action) => {
      const { id, week_id, home_team, away_team, home_image, away_image, winning_team, winning_value, status, meta_description, meta_keyword, meta_title } = action.payload || {};
      state.pickId = id || null;
      state.pickWeekId = week_id || "";
      state.pickHomeTeam = home_team || "";
      state.pickAwayTeam = away_team || "";
      state.pickHomeImage = home_image || "";
      state.pickAwayImage = away_image || "";
      state.pickWinningTeam = winning_team || "";
      state.pickWinningValue = winning_value || "";
      state.pickStatus = status || "";
      state.pickMetaDescription = meta_description || "";
      state.pickMetaKeyword = meta_keyword || "";
      state.pickMetaTitle = meta_title || "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPickContent.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getPickContent.fulfilled, (state, action) => {
        state.leads = Array.isArray(action.payload) ? action.payload : [];
        state.isLoading = false;
        state.error = null;
      })
      .addCase(getPickContent.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(deleteOurPick.fulfilled, (state, action) => {
        const deletedPickId = action.payload;
        state.leads = state.leads.filter((pick) => pick.id !== deletedPickId);
        state.isLoading = false;
        state.error = null;
      })
      .addCase(deleteOurPick.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(updatePickName.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updatePickName.fulfilled, (state) => {
        state.isLoading = false;
        state.error = null;
      })
      .addCase(updatePickName.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
        console.error("Error updating pick name:", action.error);
      })
      .addCase(addNewPick.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(addNewPick.fulfilled, (state, action) => {
        state.leads.push(action.payload);
        state.isLoading = false;
        state.error = null;
      })
      .addCase(addNewPick.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || "Failed to add a new Pick";
      });
  },
});

export const { setPickData } = pickSlice.actions;

export default pickSlice.reducer;
