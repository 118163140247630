
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from "axios";
import config from '../../config'

const API_URL = `${config.baseUrl}`;

export const getAgentsContent = createAsyncThunk(
    "mail/content",
    async () => {
      try {
        const ACCESS_TOKEN = localStorage.getItem("token");
  
        const response = await axios.get(`${API_URL}/list-user-email`, {
          headers: {
            Authorization: `Bearer ${ACCESS_TOKEN}`,
          },
        });
        return response.data.data;
      } catch (error) {
        throw error;
      }
    }
  );
  export const deleteOurUserMail = createAsyncThunk(
    "mail/delete",
    async (id = 1) => {
      try {
        await axios.delete(`${API_URL}/delete-user-email/${id}`);
  
        return id;
      } catch (error) {
        throw error;
      }
    }
  );


  export const updateAgentsName = createAsyncThunk(
    "mail/update",
    async ({ id, newName ,newEmail,newState,newCity,newCountry,newAddress,newZip,newPhoneno,newPassword}) => {
      try {
        console.log(id, newName ,newEmail,newState,newCity,newCountry,newAddress,newZip,newPhoneno,newPassword)
        const ACCESS_TOKEN = localStorage.getItem("token");
        const response = await axios.put(
          `${API_URL}/update-mail/${id}`,
          { first_name: newName, email: newEmail, state: newState , city: newCity , country: newCountry ,address: newAddress ,zip: newZip,phoneno: newPhoneno,password: newPassword },
       
          {
            headers: {
              Authorization: `Bearer ${ACCESS_TOKEN}`,
            },
          }
        );
  
        return response.data.data;
      } catch (error) {
        throw error;
      }
    }
  );
  export const addNewAgents = createAsyncThunk(
    "mail/addNew",
    async (newAgentsData) => {
      try {
        const ACCESS_TOKEN = localStorage.getItem("token");

        const response = await axios.post(
          `${API_URL}/add-mail`,
          newAgentsData,
          {
            headers: {
              Authorization: `Bearer ${ACCESS_TOKEN}`,
            },
          }
        );
  
        return response.data.data; 
      } catch (error) {
        throw error;
      }
    }
  );
	
 const initialState={
    isLoading: false,
    leads: [],
    mailId: null, 
    mailName: "",
    mailStatus:"",
    mailLastName:"",
    mailZip:"",
    mailCountry:"",
    mailState:"",
    mailCity:"",
    mailAddress:"",
    mailPhoneno:"",
    mailEmail:"",
    mailPassword:"",
    error: null,
}
export const mailSlice = createSlice({
    name: 'mail',
    initialState:initialState,
    reducers:  {
        // Define your reducers here if needed
        setAgentsData: (state, action) => {
          state.mailId = action.payload.id;
          state.mailName = action.payload.first_name;
          state.mailStatus = action.payload.status;
          state.mailZip = action.payload.zip;
          state.mailCountry = action.payload.country;
          state.mailState = action.payload.state;
          state.mailCity = action.payload.city;
          state.mailAddress= action.payload.address;
          state.mailPhoneno = action.payload.phoneno;
          state.mailEmail = action.payload.email;
          state.mailPassword= action.payload.password;
        },
      },

      extraReducers: (builder) => {
        builder
          .addCase(getAgentsContent.pending, (state) => {
            state.isLoading = true;
            state.error = null;
          })
          .addCase(getAgentsContent.fulfilled, (state, action) => {
            state.leads = action.payload;
            state.isLoading = false;
            state.error = null;
          })
          .addCase(getAgentsContent.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.error.message;
          })
          .addCase(deleteOurUserMail.fulfilled, (state, action) => {
            const deletedAgentsId = action.payload;
            state.leads = state.leads.filter(
              (mail) => mail.id !== deletedAgentsId
            );
            state.isLoading = false;
            state.error = null;
          })
          .addCase(deleteOurUserMail.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.error.message;
          })
          .addCase(updateAgentsName.pending, (state) => {
            state.isLoading = true;
            state.error = null;
          })
          .addCase(updateAgentsName.fulfilled, (state) => {
            state.isLoading = false;
            state.error = null;
          })
          .addCase(updateAgentsName.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.error.message;
            console.error("Error updating agent name:", action.error);
          })
          .addCase(addNewAgents.pending, (state) => {
            state.isLoading = true;
            state.error = null;
          })
          .addCase(addNewAgents.fulfilled, (state, action) => {
            state.leads.push(action.payload);
            state.isLoading = false;
            state.error = null;
          })
          .addCase(addNewAgents.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.error.message
              ? action.error.message
              : "Failed to add a new agent";
          });
      },
    });
    
export const { deleteLead, setAgentsData } = mailSlice.actions;
export default mailSlice.reducer;