import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import config from '../../config';

const API_URL = `${config.baseUrl}`;

// Async thunk for getting slip content
export const getSlipContent = createAsyncThunk(
  "slip/content",
  async () => {
    try {
      const ACCESS_TOKEN = localStorage.getItem("token");

      const response = await axios.get(`${API_URL}/list-slip`, {
        headers: {
          Authorization: `Bearer ${ACCESS_TOKEN}`,
        },
      });

      // Ensure response has the expected format
      if (response.data && response.data.data) {
        return response.data.data;
      } else {
        throw new Error("Unexpected response format");
      }
    } catch (error) {
      throw error;
    }
  }
);

// Async thunk for deleting a slip
export const deleteOurSlip = createAsyncThunk(
  "slip/delete",
  async (id = 1) => {
    try {
      await axios.delete(`${API_URL}/delete-slip/${id}`);
      return id;
    } catch (error) {
      throw error;
    }
  }
);

// Async thunk for updating a slip
export const updateSlipName = createAsyncThunk(
  "slip/update",
  async ({ id, image }) => {
    try {
      const ACCESS_TOKEN = localStorage.getItem("token");
      const response = await axios.put(
        `${API_URL}/update-slip/${id}`, 
        { image },
        {
          headers: {
            Authorization: `Bearer ${ACCESS_TOKEN}`,
          },
        }
      );

      // Ensure response has the expected format
      if (response.data && response.data.data) {
        return response.data.data;
      } else {
        throw new Error("Unexpected response format");
      }
    } catch (error) {
      throw error;
    }
  }
);

// Async thunk for adding a new slip
export const addNewSlip = createAsyncThunk(
  "slip/addNew",
  async (newSlipData) => {
    try {
      const ACCESS_TOKEN = localStorage.getItem("token");

      const response = await axios.post(
        `${API_URL}/add-slip`,
        newSlipData,
        {
          headers: {
            Authorization: `Bearer ${ACCESS_TOKEN}`,
          },
        }
      );

      // Ensure response has the expected format
      if (response.data && response.data.data) {
        return response.data.data;
      } else {
        throw new Error("Unexpected response format");
      }
    } catch (error) {
      throw error;
    }
  }
);

// Define the slip slice
export const slipSlice = createSlice({
  name: "slip",
  initialState: {
    isLoading: false,
    leads: [],
    slipId: null,
    slipImage: "",
    slipStatus: "",
    error: null,
  },
  reducers: {
    // Define your reducers here if needed
    setSlipData: (state, action) => {
      const { id, image } = action.payload || {};
      state.slipId = id || null;
      state.slipImage = image || "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSlipContent.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getSlipContent.fulfilled, (state, action) => {
        state.leads = Array.isArray(action.payload) ? action.payload : [];
        state.isLoading = false;
        state.error = null;
      })
      .addCase(getSlipContent.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(deleteOurSlip.fulfilled, (state, action) => {
        const deletedSlipId = action.payload;
        state.leads = state.leads.filter((slip) => slip.id !== deletedSlipId);
        state.isLoading = false;
        state.error = null;
      })
      .addCase(deleteOurSlip.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(updateSlipName.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateSlipName.fulfilled, (state, action) => {
        // Ensure action.payload is valid and contains expected data
        const updatedSlip = action.payload || {};
        const index = state.leads.findIndex((slip) => slip.id === updatedSlip.id);
        if (index !== -1) {
          state.leads[index] = updatedSlip;
        }
        state.isLoading = false;
        state.error = null;
      })
      .addCase(updateSlipName.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
        console.error("Error updating slip:", action.error);
      })
      .addCase(addNewSlip.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(addNewSlip.fulfilled, (state, action) => {
        state.leads.push(action.payload || {});
        state.isLoading = false;
        state.error = null;
      })
      .addCase(addNewSlip.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || "Failed to add a new Slip";
      });
  },
});

export const { setSlipData } = slipSlice.actions;

export default slipSlice.reducer;
