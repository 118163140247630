import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import config from '../../config';

const API_URL = `${config.baseUrl}`;

// Async thunk for getting podcast content
export const getPodcastContent = createAsyncThunk(
  "podcast/content",
  async () => {
    try {
      const ACCESS_TOKEN = localStorage.getItem("token");

      const response = await axios.get(`${API_URL}/list-podcast`, {
        headers: {
          Authorization: `Bearer ${ACCESS_TOKEN}`,
        },
      });

      // Ensure response has the expected format
      if (response.data && response.data.data) {
        return response.data.data;
      } else {
        throw new Error("Unexpected response format");
      }
    } catch (error) {
      throw error;
    }
  }
);

// Async thunk for deleting a podcast
export const deleteOurPodcast = createAsyncThunk(
  "podcast/delete",
  async (id = 1) => {
    try {
      await axios.delete(`${API_URL}/delete-podcast/${id}`);
      return id;
    } catch (error) {
      throw error;
    }
  }
);

// Async thunk for updating a podcast
export const updatePodcastName = createAsyncThunk(
  "podcast/update",
  async ({ id, audio ,title}) => {
    try {
      const ACCESS_TOKEN = localStorage.getItem("token");
      const response = await axios.put(
        `${API_URL}/update-podcast/${id}`, 
        { audio,title },
        {
          headers: {
            Authorization: `Bearer ${ACCESS_TOKEN}`,
          },
        }
      );

      // Ensure response has the expected format
      if (response.data && response.data.data) {
        return response.data.data;
      } else {
        throw new Error("Unexpected response format");
      }
    } catch (error) {
      throw error;
    }
  }
);

// Async thunk for adding a new podcast
export const addNewPodcast = createAsyncThunk(
  "podcast/addNew",
  async (newPodcastData) => {
    try {
      const ACCESS_TOKEN = localStorage.getItem("token");

      const response = await axios.post(
        `${API_URL}/add-podcast`,
        newPodcastData,
        {
          headers: {
            Authorization: `Bearer ${ACCESS_TOKEN}`,
          },
        }
      );

      // Ensure response has the expected format
      if (response.data && response.data.data) {
        return response.data.data;
      } else {
        throw new Error("Unexpected response format");
      }
    } catch (error) {
      throw error;
    }
  }
);

// Define the podcast slice
export const podcastSlice = createSlice({
  name: "podcast",
  initialState: {
    isLoading: false,
    leads: [],
    podcastId: null,
    podcastAudio: "",
    podcastTitle: "",
    podcastStatus: "",
    error: null,
  },
  reducers: {
    // Define your reducers here if needed
    setPodcastData: (state, action) => {
      const { id, audio,title } = action.payload || {};
      state.podcastId = id || null;
      state.podcastAudio = audio || "";
      state.podcastTitle = title || "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPodcastContent.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getPodcastContent.fulfilled, (state, action) => {
        state.leads = Array.isArray(action.payload) ? action.payload : [];
        state.isLoading = false;
        state.error = null;
      })
      .addCase(getPodcastContent.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(deleteOurPodcast.fulfilled, (state, action) => {
        const deletedPodcastId = action.payload;
        state.leads = state.leads.filter((podcast) => podcast.id !== deletedPodcastId);
        state.isLoading = false;
        state.error = null;
      })
      .addCase(deleteOurPodcast.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(updatePodcastName.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updatePodcastName.fulfilled, (state, action) => {
        // Ensure action.payload is valid and contains expected data
        const updatedPodcast = action.payload || {};
        const index = state.leads.findIndex((podcast) => podcast.id === updatedPodcast.id);
        if (index !== -1) {
          state.leads[index] = updatedPodcast;
        }
        state.isLoading = false;
        state.error = null;
      })
      .addCase(updatePodcastName.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
        console.error("Error updating podcast:", action.error);
      })
      .addCase(addNewPodcast.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(addNewPodcast.fulfilled, (state, action) => {
        state.leads.push(action.payload || {});
        state.isLoading = false;
        state.error = null;
      })
      .addCase(addNewPodcast.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || "Failed to add a new Podcast";
      });
  },
});

export const { setPodcastData } = podcastSlice.actions;

export default podcastSlice.reducer;
