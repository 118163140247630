import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import config from '../../config'

const API_URL = `${config.baseUrl}`;

// Async thunk for getting grid content
export const getGridContent = createAsyncThunk(
  "grid/content",
  async () => {
    try {
      const ACCESS_TOKEN = localStorage.getItem("token");

      const response = await axios.get(`${API_URL}/list-grid`, {
        headers: {
          Authorization: `Bearer ${ACCESS_TOKEN}`,
        },
      });
      return response.data.data;
    } catch (error) {
      throw error;
    }
  }
);

// Async thunk for deleting a grid
export const deleteOurGrid = createAsyncThunk(
  "grid/delete",
  async (id = 1) => {
    try {
      await axios.delete(`${API_URL}/delete-grid/${id}`);

      return id;
    } catch (error) {
      throw error;
    }
  }
);

// Async thunk for updating a grid name
export const updateGridName = createAsyncThunk(
  "grid/update",
  async (gridData) => {
    try {
      const ACCESS_TOKEN = localStorage.getItem("token");
      const response = await axios.put(
        `${API_URL}/update-grid/${gridData.id}`, 
        {
          expected_value: gridData.expected_value,
          win_percentage: gridData.win_percentage,
          major_percentage: gridData.major_percentage,
          team: gridData.team,
          future: gridData.future,
          week_info: gridData.week_info,
        },
        {
          headers: {
            Authorization: `Bearer ${ACCESS_TOKEN}`,
          },
        }
      );

      return response.data.data;
    } catch (error) {
      throw error;
    }
  }
);

// Async thunk for adding a new grid
export const addNewGrid = createAsyncThunk(
  "grid/addNew",
  async (newGridData) => {
    try {
      const ACCESS_TOKEN = localStorage.getItem("token");

      const response = await axios.post(
        `${API_URL}/add-grid`,
        newGridData,
        {
          headers: {
            Authorization: `Bearer ${ACCESS_TOKEN}`,
          },
        }
      );

      return response.data.data; // Return the newly added grid data
    } catch (error) {
      throw error;
    }
  }
);

// Define the grid slice
const gridSlice = createSlice({
  name: "grid",
  initialState: {
    isLoading: false,
    grids: [],
    gridData: {
      expected_value: "",
      win_percentage: "",
      major_percentage: "",
      team: "",
      future: "",
      week_info: [],
    },
    error: null,
  },
  reducers: {
    // Define your reducers here if needed
    setGridData: (state, action) => {
      state.gridId = action.payload.id;
      state.gridStatus = action.payload.status;
      state.gridName = action.payload.grid_name;
      state.gridFee = action.payload.grid_fee;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getGridContent.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getGridContent.fulfilled, (state, action) => {
        state.grids = action.payload;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(getGridContent.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(deleteOurGrid.fulfilled, (state, action) => {
        const deletedGridId = action.payload;
        state.grids = state.grids.filter(
          (grid) => grid.id !== deletedGridId
        );
        state.isLoading = false;
        state.error = null;
      })
      .addCase(deleteOurGrid.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(updateGridName.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateGridName.fulfilled, (state) => {
        state.isLoading = false;
        state.error = null;
      })
      .addCase(updateGridName.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
        console.error("Error updating Grid name:", action.error);
      })
      .addCase(addNewGrid.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(addNewGrid.fulfilled, (state, action) => {
        state.grids.push(action.payload); // Add the new data to the end of the array
        state.grids.reverse(); // Reverse the order of the array
        state.isLoading = false;
        state.error = null;
      })
      
      .addCase(addNewGrid.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message
          ? action.error.message
          : "Failed to add a new Grid";
      });
  },
});

export const { deleteLead, setGridData } = gridSlice.actions;

export default gridSlice.reducer;