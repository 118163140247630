import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import config from "../../config";

const API_URL = `${config.baseUrl}`


//get all promocodes
export const getPromocodes = createAsyncThunk(
    "promocode/getAll",
    async() =>{
        const ACCESS_TOKEN = localStorage.getItem("token");
        const response = await axios.get(`${API_URL}/list-promocode`,{
            headers:{
                Authorization:`Bearer ${ACCESS_TOKEN}`
            }
        })
        return response.data.data;
    }
)

//add promocode
export const addNewPromocode = createAsyncThunk(
    "promocode/addNew",
    async(newPromocodeData) =>{
        try{
            const ACCESS_TOKEN = localStorage.getItem("token");
            const response = await axios.post(`${API_URL}/add-promocode`,
                newPromocodeData,
                {
                    headers:{
                        Authorization:`Bearer ${ACCESS_TOKEN}`,
                    }
                }
            );
            return response.data.date;
        }catch(error){
            throw error;
        }
    }
)

// Update promocode

export const updatePromocode = createAsyncThunk(
    "promocode/update",
    async ({ id, code, discount, status }) => {
      try {
        const ACCESS_TOKEN = localStorage.getItem("token");
        const response = await axios.put(
          `${API_URL}/update-promocode/${id}`,
          { code, discount, status },
          {
            headers: {
              Authorization: `Bearer ${ACCESS_TOKEN}`,
            },
          }
        );
  
        return {id, ...response.data.data}
      } catch (error) {
        throw error;
      }
    }
  );
  

//Delete

export const deletePromocode = createAsyncThunk(
    "promocode/delete",
    async(id) =>{
        const ACCESS_TOKEN = localStorage.getItem("token");
        await axios.delete(`${API_URL}/delete-promocode/${id}`,{
            headers:{
                Authorization: `Bearer ${ACCESS_TOKEN}`
            }
        })
        return id;
    }
)

//Update status

export const updatePromocodeStatus = createAsyncThunk(
    "promocode/updateStatus",
    async ({id, status}) =>{
        const ACCESS_TOKEN = localStorage.getItem("token");
        const response = await axios.put( 
            `${API_URL}/status-promocode/${id}`,
            {status},
            {
                headers:{
                    Authorization: `Bearer ${ACCESS_TOKEN}`,
                }
            }
        )
        return response.data.data;
    }
)

const promoSlice = createSlice({
    name:"promo",
    initialState:{
        promocodes:[],
        loading: false,
        error:null,
    },
    reducers:{
        clearError:(state) =>{
            state.error = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getPromocodes.pending, (state) =>{
                state.loading = true;
            })
            .addCase(getPromocodes.fulfilled, (state, action) =>{
                state.promocodes = action.payload;
                state.loading = false;
            })
            .addCase(getPromocodes.rejected,(state, action) =>{
                state.loading = false;
                state.error = action.error;
            })
            .addCase(addNewPromocode.pending, (state) =>{
                state.loading = true;
            })
            .addCase(addNewPromocode.fulfilled, (state, action) =>{
                state.promocodes.push(action.payload);
                state.loading = false;
            })
            .addCase(addNewPromocode.rejected, (state, action) =>{
                state.loading= false;
                state.error = action.error;
            })
            //handleUpdate
            .addCase(updatePromocode.pending, (state) =>{
                state.loading = true;
            })
            .addCase(updatePromocode.fulfilled, (state, action) => {
                console.log("Updated Promo Response:", action.payload); // Add this line for debugging
              
                const updatedPromo = action.payload;
                const index = state.promocodes.findIndex((promo) => promo.id === updatedPromo.id);
                if (index !== -1) {
                  state.promocodes[index] = updatedPromo;
                }
                state.loading = false;
              })
                        
            .addCase(updatePromocode.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error;
            })
            //Handle deleting a promocode
            .addCase(deletePromocode.pending, (state) =>{
                state.loading = true;
            })
            .addCase(deletePromocode.fulfilled, (state, action) =>{
                state.promocodes = state.promocodes.filter(promo => promo.id !== action.payload);
                state.loading = false;
            })
            .addCase(deletePromocode.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error;
            })
            //Handle update status
            .addCase(updatePromocodeStatus.pending,(state) =>{
                state.loading = true;
            })
            .addCase(updatePromocodeStatus.fulfilled, (state, action) => {
                const index = state.promocodes.findIndex((promo) => promo.id === action.payload.id);
                if(index !== -1){
                    state.promocodes[index] = action.payload;
                }
                state.loading = false;
            })
            .addCase(updatePromocodeStatus.rejected, (state, action) =>{
                state.loading = false;
                state.error = action.error;
            })
    }
})

export const {clearError } = promoSlice.actions;

export default promoSlice.reducer;