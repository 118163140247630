
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from "axios";
import config from '../../config'

const API_URL = `${config.baseUrl}`;

export const getPaymentContent = createAsyncThunk(
    "payment/content",
    async () => {
      try {
        const ACCESS_TOKEN = localStorage.getItem("token");
  
        const response = await axios.get(`${API_URL}/list-payments-details`, {
          headers: {
            Authorization: `Bearer ${ACCESS_TOKEN}`,
          },
        });
        return response.data.data;
      } catch (error) {
        throw error;
      }
    }
);
 
  
 
 const initialState={
    isLoading: false,
    payments: [],
    error: null,
}
export const paymentSlice = createSlice({
    name: 'payment',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
          .addCase(getPaymentContent.pending, (state) => {
            state.isLoading = true;
            state.error = null;
          })
          .addCase(getPaymentContent.fulfilled, (state, action) => {
            state.payments = action.payload;
            state.isLoading = false;
            state.error = null;
          })
          .addCase(getPaymentContent.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.error.message;
          })
    }
});

export default paymentSlice.reducer;