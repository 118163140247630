import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import config from '../../config'

const API_URL = `${config.baseUrl}`;

// Async thunk for getting live content
export const getLiveContent = createAsyncThunk(
  "live/content",
  async () => {
    try {
      const ACCESS_TOKEN = localStorage.getItem("token");

      const response = await axios.get(`${API_URL}/list-live`, {
        headers: {
          Authorization: `Bearer ${ACCESS_TOKEN}`,
        },
      });

      // Ensure the response contains the expected data structure
      if (response.data && response.data.data) {
        return response.data.data;
      } else {
        throw new Error("Unexpected response format");
      }
    } catch (error) {
      throw error;
    }
  }
);

// Async thunk for deleting a live
export const deleteOurLive = createAsyncThunk(
  "live/delete",
  async (id = 1) => {
    try {
      await axios.delete(`${API_URL}/delete-live/${id}`);
      return id;
    } catch (error) {
      throw error;
    }
  }
);

// Async thunk for updating a live
export const updateLiveName = createAsyncThunk(
  "live/update",
  async ({ id, game_id, team, player_name, spread }) => {
    try {
      const ACCESS_TOKEN = localStorage.getItem("token");
      const response = await axios.put(
        `${API_URL}/update-live/${id}`,
        { game_id, team, player_name, spread },
        {
          headers: {
            Authorization: `Bearer ${ACCESS_TOKEN}`,
          },
        }
      );

      // Ensure the response contains the expected data structure
      if (response.data && response.data.data) {
        return response.data.data;
      } else {
        throw new Error("Unexpected response format");
      }
    } catch (error) {
      throw error;
    }
  }
);

// Async thunk for adding a new live
export const addNewLive = createAsyncThunk(
  "live/addNew",
  async (newLiveData) => {
    try {
      const ACCESS_TOKEN = localStorage.getItem("token");

      const response = await axios.post(
        `${API_URL}/add-live`,
        newLiveData,
        {
          headers: {
            Authorization: `Bearer ${ACCESS_TOKEN}`,
          },
        }
      );

      // Ensure the response contains the expected data structure
      if (response.data && response.data.data) {
        return response.data.data;
      } else {
        throw new Error("Unexpected response format");
      }
    } catch (error) {
      throw error;
    }
  }
);

// Define the live slice
export const liveSlice = createSlice({
  name: "live",
  initialState: {
    isLoading: false,
    leads: [],
    liveId: null,
    liveGameId: "",
    liveTeam: "",
    livePlayerName: "",
    liveSpread: "",
    liveStatus: "",
    error: null,
  },
  reducers: {
    // Define your reducers here if needed
    setLiveData: (state, action) => {
      const { id, game_id, team, player_name, spread } = action.payload;
      state.liveId = id || null;
      state.liveGameId = game_id || "";
      state.liveTeam = team || "";
      state.livePlayerName = player_name || "";
      state.liveSpread = spread || "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLiveContent.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getLiveContent.fulfilled, (state, action) => {
        state.leads = action.payload || [];
        state.isLoading = false;
        state.error = null;
      })
      .addCase(getLiveContent.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(deleteOurLive.fulfilled, (state, action) => {
        const deletedLiveId = action.payload;
        state.leads = state.leads.filter(
          (live) => live.id !== deletedLiveId
        );
        state.isLoading = false;
        state.error = null;
      })
      .addCase(deleteOurLive.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(updateLiveName.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateLiveName.fulfilled, (state) => {
        state.isLoading = false;
        state.error = null;
      })
      .addCase(updateLiveName.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(addNewLive.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(addNewLive.fulfilled, (state, action) => {
        state.leads.push(action.payload);
        state.isLoading = false;
        state.error = null;
      })
      .addCase(addNewLive.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || "Failed to add a new Live";
      });
  },
});

export const { deleteLead, setLiveData } = liveSlice.actions;

export default liveSlice.reducer;
