import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import config from '../../config';

const API_URL = `${config.baseUrl}`;

// Async thunk for getting future content
export const getFutureContent = createAsyncThunk(
  "future/content",
  async () => {
    try {
      const ACCESS_TOKEN = localStorage.getItem("token");

      const response = await axios.get(`${API_URL}/list-future`, {
        headers: {
          Authorization: `Bearer ${ACCESS_TOKEN}`,
        },
      });

      // Ensure the response contains the expected data structure
      if (response.data && response.data.data) {
        return response.data.data;
      } else {
        throw new Error("Unexpected response format");
      }
    } catch (error) {
      throw error;
    }
  }
);

// Async thunk for deleting a future
export const deleteOurFuture = createAsyncThunk(
  "future/delete",
  async (id = 1) => {
    try {
      await axios.delete(`${API_URL}/delete-future/${id}`);
      return id;
    } catch (error) {
      throw error;
    }
  }
);

// Async thunk for updating a future
export const updateFutureName = createAsyncThunk(
  "future/update",
  async ({ id, game_id, team, player_name, spread }) => {
    try {
      const ACCESS_TOKEN = localStorage.getItem("token");
      const response = await axios.put(
        `${API_URL}/update-future/${id}`,
        { game_id, team, player_name, spread },
        {
          headers: {
            Authorization: `Bearer ${ACCESS_TOKEN}`,
          },
        }
      );

      // Ensure the response contains the expected data structure
      if (response.data && response.data.data) {
        return response.data.data;
      } else {
        throw new Error("Unexpected response format");
      }
    } catch (error) {
      throw error;
    }
  }
);

// Async thunk for adding a new future
export const addNewFuture = createAsyncThunk(
  "future/addNew",
  async (newFutureData) => {
    try {
      const ACCESS_TOKEN = localStorage.getItem("token");

      const response = await axios.post(
        `${API_URL}/add-future`,
        newFutureData,
        {
          headers: {
            Authorization: `Bearer ${ACCESS_TOKEN}`,
          },
        }
      );

      // Ensure the response contains the expected data structure
      if (response.data && response.data.data) {
        return response.data.data;
      } else {
        throw new Error("Unexpected response format");
      }
    } catch (error) {
      throw error;
    }
  }
);

// Define the future slice
export const futureSlice = createSlice({
  name: "future",
  initialState: {
    isLoading: false,
    leads: [],
    futureId: null,
    futureGameId: "",
    futureTeam: "",
    futurePlayerName: "",
    futureSpread: "",
    futureStatus: "",
    error: null,
  },
  reducers: {
    // Define your reducers here if needed
    setFutureData: (state, action) => {
      const { id, game_id, team, player_name, spread } = action.payload || {};
      state.futureId = id || null;
      state.futureGameId = game_id || "";
      state.futureTeam = team || "";
      state.futurePlayerName = player_name || "";
      state.futureSpread = spread || "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFutureContent.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getFutureContent.fulfilled, (state, action) => {
        state.leads = Array.isArray(action.payload) ? action.payload : [];
        state.isLoading = false;
        state.error = null;
      })
      .addCase(getFutureContent.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(deleteOurFuture.fulfilled, (state, action) => {
        const deletedFutureId = action.payload;
        state.leads = state.leads.filter(
          (future) => future.id !== deletedFutureId
        );
        state.isLoading = false;
        state.error = null;
      })
      .addCase(deleteOurFuture.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(updateFutureName.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateFutureName.fulfilled, (state) => {
        state.isLoading = false;
        state.error = null;
      })
      .addCase(updateFutureName.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
        console.error("Error updating future name:", action.error);
      })
      .addCase(addNewFuture.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(addNewFuture.fulfilled, (state, action) => {
        state.leads.push(action.payload);
        state.isLoading = false;
        state.error = null;
      })
      .addCase(addNewFuture.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || "Failed to add a new Future";
      });
  },
});

export const { setFutureData } = futureSlice.actions;

export default futureSlice.reducer;
