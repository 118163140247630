import { configureStore } from "@reduxjs/toolkit";
import headerSlice from "../features/common/headerSlice";
import modalSlice from "../features/common/modalSlice";
import rightDrawerSlice from "../features/common/rightDrawerSlice";
import blogSlice from "../features/blog/blogSlice";
import weekSlice from "../features/week/weekSlice";
import opponentSlice from "../features/opponent/opponentSlice";
import gridSlice from "../features/surviourGrid/gridSlice";
import topSlice from "../features/top/topSlice";
import pickSlice from "../features/pick/pickSlice";
import articleSlice from "../features/article/articleSlice";
import liveSlice from "../features/adamlive/liveSlice";
import futureSlice from "../features/adamfuture/futureSlice";
import slipSlice from "../features/adamslip/slipSlice";
import gameSlice from "../features/game/gameSlice";
import betSlice from "../features/wadebet/betSlice";
import agentsSlice from "../features/UserData/userSlice";
import mailSlice from "../features/UserEmail/userSlice"
import winSlice from "../features/win/winSlice";
import recapSlice from "../features/adamrecap/recapSlice";
import contactSlice from "../features/contactus/contactSlice";
import podcastSlice from "../features/podcast/podcastSlice";
import paymentSlice  from "../features/Userpayments/userSlice";
import promoSlice from "../features/promocodes/promoSlice";
import messageSlice from "../features/sendmessage/messageSlice";

const combinedReducer = {
  header: headerSlice,
  agents: agentsSlice,
  rightDrawer: rightDrawerSlice,
  modal: modalSlice,
  blog: blogSlice,
  week: weekSlice,
  opponent: opponentSlice,
  grid:gridSlice,
  top:topSlice,
  pick:pickSlice,
  article:articleSlice,
  live:liveSlice,
  future:futureSlice,
  slip:slipSlice,
  game:gameSlice,
  bet:betSlice,
  mail:mailSlice,
  win:winSlice,
  recap:recapSlice,
  contact:contactSlice,
  podcast:podcastSlice,
  payment:paymentSlice,
  promo:promoSlice,
  sendMessage:messageSlice,
};

export default configureStore({
  reducer: combinedReducer,
});
