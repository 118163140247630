import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import config from '../../config'

const API_URL = `${config.baseUrl}`;

// Async thunk for getting contact content
export const getContactContent = createAsyncThunk(
  "contact/content",
  async () => {
    try {
      const ACCESS_TOKEN = localStorage.getItem("token");

      const response = await axios.get(`${API_URL}/list-contact`, {
        headers: {
          Authorization: `Bearer ${ACCESS_TOKEN}`,
        },
      });

      // Ensure the response contains the expected data structure
      if (response.data && response.data.data) {
        return response.data.data;
      } else {
        throw new Error("Unexpected response format");
      }
    } catch (error) {
      throw error;
    }
  }
);

// Async thunk for deleting a contact
export const deleteOurContact = createAsyncThunk(
  "contact/delete",
  async (id = 1) => {
    try {
      await axios.delete(`${API_URL}/delete-contact/${id}`);
      return id;
    } catch (error) {
      throw error;
    }
  }
);

// Define the contact slice
export const contactSlice = createSlice({
  name: "contact",
  initialState: {
    isLoading: false,
    leads: [],
    contactId: null,
    contactGameId: "",
    contactTeam: "",
    contactPlayerName: "",
    contactSpread: "",
    contactStatus: "",
    error: null,
  },
  reducers: {
    // Define your reducers here if needed
    setContactData: (state, action) => {
      const { id, game_id, team, player_name, spread } = action.payload;
      state.contactId = id || null;
      state.contactGameId = game_id || "";
      state.contactTeam = team || "";
      state.contactPlayerName = player_name || "";
      state.contactSpread = spread || "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getContactContent.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getContactContent.fulfilled, (state, action) => {
        state.leads = action.payload || [];
        state.isLoading = false;
        state.error = null;
      })
      .addCase(getContactContent.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(deleteOurContact.fulfilled, (state, action) => {
        const deletedContactId = action.payload;
        state.leads = state.leads.filter(
          (contact) => contact.id !== deletedContactId
        );
        state.isLoading = false;
        state.error = null;
      })
      .addCase(deleteOurContact.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
     
  },
});

export const { deleteLead, setContactData } = contactSlice.actions;

export default contactSlice.reducer;
