import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import config from "../../config";

const API_URL = `${config.baseUrl}`;

export const sendMessage = createAsyncThunk(
  "message/send",
  async (messageData) => {
    try {
      const ACCESS_TOKEN = localStorage.getItem("token");
      const response = await axios.post(
        `${API_URL}/send-bulksms`,
        messageData,
        {
          headers: {
            Authorization: `Bearer ${ACCESS_TOKEN}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const getMessages = createAsyncThunk(
    "message/getAll",
    async() =>{
        const ACCESS_TOKEN = localStorage.getItem("token");
        const response = await axios.get(`${API_URL}/get-sms`,{
            headers:{
                Authorization:`Bearer ${ACCESS_TOKEN}`
            }
        })
        return response.data.data;
    }
)

//Delete Message

export const deleteMessage = createAsyncThunk(
    "message/delete",
    async (id) => {
      const ACCESS_TOKEN = localStorage.getItem("token");
      console.log('Deleting message with id:', id);
      const response = await axios.delete(`${API_URL}/delete-sms/${id}`, {
        headers: {
          Authorization: `Bearer ${ACCESS_TOKEN}`,
        },
      });
      console.log('Delete response:', response);
      return id; // returning id so that the reducer knows which message to delete
    }
  );

const messageSlice = createSlice({
  name:"msg",
  initialState: {
    messages: [], 
    loading: false,
    error: null,
  },
  reducers: {
    clearMessageStatus: (state) => {
      state.success = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(sendMessage.pending, (state) => {
        state.loading = true;
        state.success = null;
      })
      .addCase(sendMessage.fulfilled, (state, action) => {
        state.loading = false;
        state.success = action.payload;
      })
      .addCase(sendMessage.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      })
      // For fetching messages
      .addCase(getMessages.pending, (state) =>{
        state.loading = true;
    })
    .addCase(getMessages.fulfilled, (state, action) =>{
        state.messages = action.payload || [];
        state.loading = false;
    })
    .addCase(getMessages.rejected,(state, action) =>{
        state.loading = false;
        state.error = action.error;
    })
    .addCase(deleteMessage.pending, (state) =>{
        state.loading = true;
    })
    .addCase(deleteMessage.fulfilled, (state, action) => {
        console.log('Delete message with id:', action.payload);
        state.messages = state.messages.filter(message => message.id !== action.payload);
        state.loading = false;
      })
    .addCase(deleteMessage.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
    })
  },
});

export const { clearMessageStatus } = messageSlice.actions;

export default messageSlice.reducer;
