import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import config from '../../config'

const API_URL = `${config.baseUrl}`;

// Async thunk for getting bet content
export const getBetContent = createAsyncThunk(
  "bet/content",
  async () => {
    try {
      const ACCESS_TOKEN = localStorage.getItem("token");

      const response = await axios.get(`${API_URL}/list-bet`, {
        headers: {
          Authorization: `Bearer ${ACCESS_TOKEN}`,
        },
      });

      // Ensure the response contains the expected data structure
      if (response.data && response.data.data) {
        return response.data.data;
      } else {
        throw new Error("Unexpected response format");
      }
    } catch (error) {
      throw error;
    }
  }
);

// Async thunk for deleting a bet
export const deleteOurBet = createAsyncThunk(
  "bet/delete",
  async (id = 1) => {
    try {
      await axios.delete(`${API_URL}/delete-bet/${id}`);
      return id;
    } catch (error) {
      throw error;
    }
  }
);

// Async thunk for updating a bet
export const updateBetName = createAsyncThunk(
  "bet/update",
  async ({ id, game_id, team, player_name, spread }) => {
    try {
      const ACCESS_TOKEN = localStorage.getItem("token");
      const response = await axios.put(
        `${API_URL}/update-bet/${id}`,
        { game_id, team, player_name, spread },
        {
          headers: {
            Authorization: `Bearer ${ACCESS_TOKEN}`,
          },
        }
      );

      // Ensure the response contains the expected data structure
      if (response.data && response.data.data) {
        return response.data.data;
      } else {
        throw new Error("Unexpected response format");
      }
    } catch (error) {
      throw error;
    }
  }
);

// Async thunk for adding a new bet
export const addNewBet = createAsyncThunk(
  "bet/addNew",
  async (newBetData) => {
    try {
      const ACCESS_TOKEN = localStorage.getItem("token");

      const response = await axios.post(
        `${API_URL}/add-bet`,
        newBetData,
        {
          headers: {
            Authorization: `Bearer ${ACCESS_TOKEN}`,
          },
        }
      );

      // Ensure the response contains the expected data structure
      if (response.data && response.data.data) {
        return response.data.data;
      } else {
        throw new Error("Unexpected response format");
      }
    } catch (error) {
      throw error;
    }
  }
);

// Define the bet slice
export const betSlice = createSlice({
  name: "bet",
  initialState: {
    isLoading: false,
    leads: [],
    betId: null,
    betGameId: "",
    betTeam: "",
    betPlayerName: "",
    betSpread: "",
    betStatus: "",
    error: null,
  },
  reducers: {
    // Define your reducers here if needed
    setBetData: (state, action) => {
      const { id, game_id, team, player_name, spread } = action.payload;
      state.betId = id || null;
      state.betGameId = game_id || "";
      state.betTeam = team || "";
      state.betPlayerName = player_name || "";
      state.betSpread = spread || "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBetContent.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getBetContent.fulfilled, (state, action) => {
        state.leads = action.payload || [];
        state.isLoading = false;
        state.error = null;
      })
      .addCase(getBetContent.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(deleteOurBet.fulfilled, (state, action) => {
        const deletedBetId = action.payload;
        state.leads = state.leads.filter(
          (bet) => bet.id !== deletedBetId
        );
        state.isLoading = false;
        state.error = null;
      })
      .addCase(deleteOurBet.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(updateBetName.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateBetName.fulfilled, (state) => {
        state.isLoading = false;
        state.error = null;
      })
      .addCase(updateBetName.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(addNewBet.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(addNewBet.fulfilled, (state, action) => {
        state.leads.push(action.payload);
        state.isLoading = false;
        state.error = null;
      })
      .addCase(addNewBet.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || "Failed to add a new Bet";
      });
  },
});

export const { deleteLead, setBetData } = betSlice.actions;

export default betSlice.reducer;
