import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import config from '../../config'

const API_URL = `${config.baseUrl}`;

// Async thunk for getting game content
export const getGameContent = createAsyncThunk(
  "game/content",
  async () => {
    try {
      const ACCESS_TOKEN = localStorage.getItem("token");

      const response = await axios.get(`${API_URL}/list-game`, {
        headers: {
          Authorization: `Bearer ${ACCESS_TOKEN}`,
        },
      });
      return response.data.data;
    } catch (error) {
      throw error;
    }
  }
);

// Async thunk for deleting a game
export const deleteOurGame = createAsyncThunk(
  "game/delete",
  async (id = 1) => {
    try {
      await axios.delete(`${API_URL}/delete-game/${id}`);

      return id;
    } catch (error) {
      throw error;
    }
  }
);

// Async thunk for updating a game name
export const updateGameName = createAsyncThunk(
  "game/update",
  async ({ id, newName,newShortDescription,newFee,newDescription ,newMetaTitle,newMetaDescription }) => {
    try {
      const ACCESS_TOKEN = localStorage.getItem("token");
      const response = await axios.put(
        `${API_URL}/update-game/${id}`, 
        { game_name: newName,game_fee:newFee,short_description:newShortDescription,description:newDescription,meta_title:newMetaTitle,meta_description:newMetaDescription },
        {
          headers: {
            Authorization: `Bearer ${ACCESS_TOKEN}`,
          },
        }
      );

      return response.data.data;
    } catch (error) {
      throw error;
    }
  }
);

// Async thunk for adding a new game
export const addNewGame = createAsyncThunk(
  "game/addNew",
  async (newGameData) => {
    try {
      const ACCESS_TOKEN = localStorage.getItem("token");

      const response = await axios.post(
        `${API_URL}/add-game`,
        newGameData,
        {
          headers: {
            Authorization: `Bearer ${ACCESS_TOKEN}`,
          },
        }
      );

      return response.data.data; // Return the newly added game data
    } catch (error) {
      throw error;
    }
  }
);

// Define the game slice
export const gameSlice = createSlice({
  name: "game",
  initialState: {
    isLoading: false,
    games: [],
    gameId: null, 
    gameName: "",
    gameFee:"",
    gameStatus:"",
    error: null,
  },
  reducers: {
    // Define your reducers here if needed
    setGameData: (state, action) => {
      state.gameId = action.payload.id;
      state.gameStatus = action.payload.status;
      state.gameName = action.payload.game_name;
      state.gameFee = action.payload.game_fee;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getGameContent.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getGameContent.fulfilled, (state, action) => {
        state.games = action.payload;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(getGameContent.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(deleteOurGame.fulfilled, (state, action) => {
        const deletedGameId = action.payload;
        state.games = state.games.filter(
          (game) => game.id !== deletedGameId
        );
        state.isLoading = false;
        state.error = null;
      })
      .addCase(deleteOurGame.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(updateGameName.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateGameName.fulfilled, (state) => {
        state.isLoading = false;
        state.error = null;
      })
      .addCase(updateGameName.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
        console.error("Error updating Game name:", action.error);
      })
      .addCase(addNewGame.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(addNewGame.fulfilled, (state, action) => {
        state.games.push(action.payload); // Add the new data to the end of the array
        state.games.reverse(); // Reverse the order of the array
        state.isLoading = false;
        state.error = null;
      })
      
      .addCase(addNewGame.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message
          ? action.error.message
          : "Failed to add a new Game";
      });
  },
});

export const { deleteLead, setGameData } = gameSlice.actions;

export default gameSlice.reducer;