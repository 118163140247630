import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import config from '../../config';

const API_URL = `${config.baseUrl}`;

// Async thunk for getting recap content
export const getRecapContent = createAsyncThunk(
  "recap/content",
  async () => {
    try {
      const ACCESS_TOKEN = localStorage.getItem("token");

      const response = await axios.get(`${API_URL}/list-recap`, {
        headers: {
          Authorization: `Bearer ${ACCESS_TOKEN}`,
        },
      });

      // Ensure response has the expected format
      if (response.data && response.data.data) {
        return response.data.data;
      } else {
        throw new Error("Unexpected response format");
      }
    } catch (error) {
      throw error;
    }
  }
);

// Async thunk for deleting a recap
export const deleteOurRecap = createAsyncThunk(
  "recap/delete",
  async (id = 1) => {
    try {
      await axios.delete(`${API_URL}/delete-recap/${id}`);
      return id;
    } catch (error) {
      throw error;
    }
  }
);

// Async thunk for updating a recap
export const updateRecapName = createAsyncThunk(
  "recap/update",
  async ({ id, image ,video,title}) => {
    try {
      const ACCESS_TOKEN = localStorage.getItem("token");
      const response = await axios.put(
        `${API_URL}/update-recap/${id}`, 
        { image,video,title },
        {
          headers: {
            Authorization: `Bearer ${ACCESS_TOKEN}`,
          },
        }
      );

      // Ensure response has the expected format
      if (response.data && response.data.data) {
        return response.data.data;
      } else {
        throw new Error("Unexpected response format");
      }
    } catch (error) {
      throw error;
    }
  }
);

// Async thunk for adding a new recap
export const addNewRecap = createAsyncThunk(
  "recap/addNew",
  async (newRecapData) => {
    try {
      const ACCESS_TOKEN = localStorage.getItem("token");

      const response = await axios.post(
        `${API_URL}/add-recap`,
        newRecapData,
        {
          headers: {
            Authorization: `Bearer ${ACCESS_TOKEN}`,
          },
        }
      );

      // Ensure response has the expected format
      if (response.data && response.data.data) {
        return response.data.data;
      } else {
        throw new Error("Unexpected response format");
      }
    } catch (error) {
      throw error;
    }
  }
);

// Define the recap slice
export const recapSlice = createSlice({
  name: "recap",
  initialState: {
    isLoading: false,
    leads: [],
    recapId: null,
    recapImage: "",
    recapVideo: "",
    recapTitle: "",
    recapStatus: "",
    error: null,
  },
  reducers: {
    // Define your reducers here if needed
    setRecapData: (state, action) => {
      const { id, image,video,title } = action.payload || {};
      state.recapId = id || null;
      state.recapImage = image || "";
      state.recapVideo = video || "";
      state.recapTitle = title || "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRecapContent.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getRecapContent.fulfilled, (state, action) => {
        state.leads = Array.isArray(action.payload) ? action.payload : [];
        state.isLoading = false;
        state.error = null;
      })
      .addCase(getRecapContent.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(deleteOurRecap.fulfilled, (state, action) => {
        const deletedRecapId = action.payload;
        state.leads = state.leads.filter((recap) => recap.id !== deletedRecapId);
        state.isLoading = false;
        state.error = null;
      })
      .addCase(deleteOurRecap.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(updateRecapName.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateRecapName.fulfilled, (state, action) => {
        // Ensure action.payload is valid and contains expected data
        const updatedRecap = action.payload || {};
        const index = state.leads.findIndex((recap) => recap.id === updatedRecap.id);
        if (index !== -1) {
          state.leads[index] = updatedRecap;
        }
        state.isLoading = false;
        state.error = null;
      })
      .addCase(updateRecapName.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
        console.error("Error updating recap:", action.error);
      })
      .addCase(addNewRecap.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(addNewRecap.fulfilled, (state, action) => {
        state.leads.push(action.payload || {});
        state.isLoading = false;
        state.error = null;
      })
      .addCase(addNewRecap.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || "Failed to add a new Recap";
      });
  },
});

export const { setRecapData } = recapSlice.actions;

export default recapSlice.reducer;
