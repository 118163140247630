import React, { lazy, useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { themeChange } from 'theme-change';
import checkAuth from './app/auth';
import initializeApp from './app/init';

// Importing pages
const Layout = lazy(() => import('./containers/Layout'));
const Login = lazy(() => import('./pages/Login'));
const ForgotPassword = lazy(() => import('./pages/ForgotPassword'));
const Register = lazy(() => import('./pages/Register'));
const Documentation = lazy(() => import('./pages/Documentation'));
const Profile = lazy(() => import('./pages/protected/ProfileSettings'));

// Initializing different libraries
initializeApp();

// Check for login and initialize axios
const token = checkAuth();

function App() {

  useEffect(() => {
    themeChange(false);
  }, []);

  return (
    <Router basename={'/admin'}>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/register" element={<Register />} />
        <Route path="/documentation" element={<Documentation />} />
        <Route path="/settings-profile" element={<Profile />} />
        
        {/* Layout for main application routes */}
        <Route path="/app/*" element={<Layout />} />
        
        {/* Redirect to login or welcome based on token */}
        <Route path="/404" element={<Navigate to={token ? "/welcome" : "/login"} replace />} />
        
        {/* Redirect to 404 for all other unknown routes */}
        <Route path="*" element={<Navigate to="/404" replace />} />
      </Routes>
    </Router>
  );
}

export default App;
